// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    AppstoreAddOutlined,
    DatabaseOutlined,
} from '@ant-design/icons';

// icons
const icons = {
    DatabaseOutlined,AppstoreAddOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
    id: 'group-reports',
    title: "Ads",
    type: 'group',
    children: [
        {
            id: 'categories',
            title: 'Categories',
            type: 'item',
            url: '/categories',
            icon: icons.DatabaseOutlined,
        },
        {
            id: 'addons',
            title: 'Addon',
            type: 'item',
            url: '/addons',
            icon: icons.AppstoreAddOutlined,
        },
    ]
};

export default reports;
