// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    HistoryOutlined
} from '@ant-design/icons';

// icons
const icons = {
    HistoryOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
    id: 'group-reports',
    title: "Referral",
    type: 'group',
    children: [
        {
            id: 'referral-reward-history',
            title: 'Reward History',
            type: 'item',
            url: '/referral-reward-history',
            icon: icons.HistoryOutlined,
        },
    ]
};

export default reports;
