import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    adAddons: [],
};

const adAddon = createSlice({
    name: "adAddon",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setAdAddons(state, action) {
            state.adAddons = action.payload;
        },
    },
});

export default adAddon.reducer;

export const { hasError, setLoading, setAdAddons } = adAddon.actions;

// API function to get all adAddons
export function getAllAdAddons() {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await axios.get("/ad-addons");
            // Sort by createdAt in descending order
            const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            dispatch(setAdAddons(sorted));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
        }
    };
}

// API function to add a new adAddon
export function addAdAddon(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));
            const response = await axios.post("/ad-addons", data);
            dispatch(setAdAddons([response.data,...getState().adAddon.adAddons]));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
        }
    };
}

// API function to update an existing adAddon
export function updateAdAddon(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));
            await axios.patch(`/ad-addons/${data._id}`, data);
            const updatedAdAddons = getState().adAddon.adAddons.map((adAddon) =>
                adAddon?._id === data?._id ? { ...adAddon, ...data } : adAddon
            );
            dispatch(setAdAddons(updatedAdAddons));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
        }
    };
}

// API function to delete an existing adAddon
export function deleteAdAddon(id) {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));
            await axios.delete(`/ad-addons/${id}`);
            const filteredAdAddons = getState().adAddon.adAddons.filter(
                (adAddon) => adAddon.id !== id
            );
            dispatch(setAdAddons(filteredAdAddons));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
        }
    };
}

// API function to get an adAddon by its ID
export function getAdAddonById(id) {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await axios.get(`/ad-addons/${id}`);
            dispatch(setLoading(false));
            return response.data;
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
            return null;
        }
    };
}
