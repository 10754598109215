// project import
import questions from "./questions";
import assets from "./assets";
import coinTransferHistory from "./coin-transfer-history";
import packages from "./packages";
import rewards from "./rewards";
import generalSettings from "./general-settings";
import userModules from "./user-modules";
import order from "./order";
import broadcasts from "./broadcasts";
import ads from "./ads";
import tembler from "./tembler";
import referral from "./referral";


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [userModules,order,generalSettings,broadcasts,coinTransferHistory,ads,rewards, tembler, referral, assets, packages,questions ]
};

export default menuItems;
