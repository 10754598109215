import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    isLoading: false,
    userModules: [],
    totalPages: 0,
};

const slice = createSlice({
    name: "userModule",
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        setUserModules(state, action) {
            state.userModules = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllUserModules(perPage, page, query, role='USER') {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const {name,email,gender} = query;
            const response = await axios.get("/users", {
                params: {
                    page,
                    perPage,
                    role,
                    query: name || email || gender,
                },
            });
            await dispatch(slice.actions.setUserModules(response.data.data));
            await dispatch(slice.actions.setTotalPages(response.data.totalPages));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}

export function updateUserModule(data) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const response = await axios.patch(`/users/${data._id}`, data);
            // Dispatch actions to update the user in state
            const updated = getState().userModule.userModules.map((userModule) =>
                userModule?._id === data?._id ? { ...userModule, ...data } : userModule
            );
            dispatch(slice.actions.setUserModules(updated));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}
