import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    packs: [],
};

const pack = createSlice({
    name: "pack",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setpacks(state, action) {
            state.packs = action.payload;
        },
    },
});

export default pack.reducer;

export const { hasError, setLoading, setpacks } = pack.actions;

// API function to get all packs
export const getAllPacks = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/packages");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setpacks(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new pack
export const addPack = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/packages", data);
        dispatch(setpacks([...getState().pack.packs, response.data]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to update an existing pack
export const updatePack = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.patch(`/packages/${data._id}`, data);
        const updatedpacks = getState().pack.packs.map((pack) =>
            pack._id === data?._id ? { ...pack, ...data } : pack
        );
        dispatch(setpacks(updatedpacks));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to delete an existing pack
export const deletePack = (id) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.delete(`/packages/${id}`);
        const filteredpacks = getState().pack.packs.filter(
            (pack) => pack.id !== id
        );
        dispatch(setpacks(filteredpacks));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

