import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    creditCards: [],
};

const creditCard = createSlice({
    name: "creditCard",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setcreditCards(state, action) {
            state.creditCards = action.payload;
        },
    },
});

export default creditCard.reducer;

export const { hasError, setLoading, setcreditCards } = creditCard.actions;

// API function to get all creditCards
export const getAllCreditCards = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/cards");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setcreditCards(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new creditCard
export const addCreditCard = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/cards", data);
        dispatch(setcreditCards([response.data, ...getState().creditCard.creditCards]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to update an existing creditCard
export const updateCreditCard = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.patch(`/cards/${data._id}`, data);
        const updatedcreditCards = getState().creditCard.creditCards.map((creditCard) =>
            creditCard?._id === data?._id ? { ...creditCard, ...data } : creditCard
        );
        dispatch(setcreditCards(updatedcreditCards));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to delete an existing creditCard
export const deleteCreditCard = (id) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.delete(`/cards/${id}`);
        const filteredcreditCards = getState().creditCard.creditCards.filter(
            (creditCard) => creditCard._id !== id
        );
        dispatch(setcreditCards(filteredcreditCards));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

