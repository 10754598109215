// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    GiftOutlined,AppstoreOutlined
} from '@ant-design/icons';

// icons
const icons = {
    GiftOutlined,AppstoreOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
    id: 'group-reports',
    title: "Rewards",
    type: 'group',
    children: [
        {
            id: 'rewards',
            title: 'Reward Items',
            type: 'item',
            url: '/rewards',
            icon: icons.AppstoreOutlined,
        },
        {
            id: 'reward-history',
            title: 'Rewards',
            type: 'item',
            url: '/reward-history',
            icon: icons.GiftOutlined,
        }
    ]
};

export default reports;
