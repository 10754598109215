// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {HistoryOutlined} from '@ant-design/icons';

// icons
const icons = {
    HistoryOutlined
};

// ==============================|| MENU ITEMS - Coin Transfer History ||============================== //

const dashboard = {
    id: 'group-dashboard',
    type: 'group',
    children: [
        {
            id: 'coin-transfer-history',
            title: 'Coin Transfer History',
            type: 'item',
            url: '/coin-transfer-history',
            icon: icons.HistoryOutlined,
        }
    ]
};

export default dashboard;
