import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    image: {}
};

const image = createSlice({
    name: "image",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        uploadImage(state, action) {
            state.image = action.payload;
        },
    },
});

export default image.reducer;

export const { hasError, setLoading, uploadImage } = image.actions;

export const imageUpload = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/save-file", data);
        dispatch(uploadImage(response.data));
        dispatch(setLoading(false));
        return response.data;
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

