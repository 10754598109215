import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    rewardHistorys: [],
};

const rewardHistory = createSlice({
    name: "rewardHistory",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setrewardHistorys(state, action) {
            state.rewardHistorys = action.payload;
        },
    },
});

export default rewardHistory.reducer;

export const { hasError, setLoading, setrewardHistorys } = rewardHistory.actions;

// API function to get all rewardHistorys
export const getAllRewardHistorys = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/reward-history");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setrewardHistorys(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new rewardHistory
export const addRewardHistory = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/reward-history", data);
        dispatch(setrewardHistorys([...getState().rewardHistory.rewardHistorys, response.data]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to update an existing rewardHistory
export const updateRewardHistory = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.patch(`/reward-history/${data._id}/update-status`, data);
        const updatedrewardHistorys = getState().rewardHistory.rewardHistorys.map((rewardHistory) =>
            rewardHistory?._id === data?._id ? { ...rewardHistory, ...data } : rewardHistory
        );
        dispatch(setrewardHistorys(updatedrewardHistorys));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to delete an existing rewardHistory
export const deleteRewardHistory = (id) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.delete(`/reward-history/${id}`);
        const filteredrewardHistorys = getState().rewardHistory.rewardHistorys.filter(
            (rewardHistory) => rewardHistory.id !== id
        );
        dispatch(setrewardHistorys(filteredrewardHistorys));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

