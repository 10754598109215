import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    isLoading: false,
    assets: [],
    totalPages: 0,
};

const slice = createSlice({
    name: "asset",
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        setAssets(state, action) {
            state.assets = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllAssets(perPage, page, query, withPopulate = true) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const { title } = query;
            const response = await axios.get("/assets", {
                params: {
                    page,
                    perPage,
                    withPopulate,
                    query: title,
                },
            });
            await dispatch(slice.actions.setAssets(response.data.data));
            await dispatch(slice.actions.setTotalPages(response.data.totalPages));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}


export function addAsset(assetData) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const response = await axios.post("/assets", assetData);
            // Dispatch action to add asset in state
            const updatedAssets = [response.data,...getState().asset.assets, ];
            await dispatch(slice.actions.setAssets(updatedAssets));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}

export function updateAsset(data) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const response = await axios.patch(`/assets/${data._id}`, data);
            // Extract the updated asset from the response, if provided by your API
            const updatedAsset = response.data;

            // Get the current state of assets
            const currentAssets = getState().asset.assets;

            // Map through the assets and update the one with the matching ID
            const updatedAssets = currentAssets.map((asset) =>
                asset._id === data._id ? { ...asset, ...updatedAsset } : asset
            );
            // Dispatch the action to update the assets in the state
            dispatch(slice.actions.setAssets(updatedAssets));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}

export function deleteAsset(assetId) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            await axios.delete(`/assets/${assetId}`);
            // Dispatch action to delete asset from state
            const updatedAssets = getState().asset.assets.filter((asset) => asset._id !== assetId);
            await dispatch(slice.actions.setAssets(updatedAssets));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}
