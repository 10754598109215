import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';
import image from 'assets/images/icons/Logo.png';
import {Link} from "@mui/material";


// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({open}) => {
    const theme = useTheme();

    return (
        <Link href='/' style={{display: "flex", alignItems: "center", justifyContent:"center", marginTop: 15, textDecoration:"none", color: "black", cursor: "pointer"}}>
            <img src={image} alt="logo" style={{width: open ? 'auto' : 45, height: 45, objectFit: "contain", marginRight:5}}/>
            {open && <h1>WARM Admin</h1>}
        </Link>
    // <DrawerHeaderStyled theme={theme} open={open}>
    //     <Logo isIcon={!open} sx={{width: open ? 'auto' : 35, height: 35}}/>
    // </DrawerHeaderStyled>
)
    ;
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
