import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    rewards: [],
};

const reward = createSlice({
    name: "reward",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setrewards(state, action) {
            state.rewards = action.payload;
        },
    },
});

export default reward.reducer;

export const { hasError, setLoading, setrewards } = reward.actions;

// API function to get all rewards
export const getAllRewards = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/rewards");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setrewards(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new reward
export const addReward = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/rewards", data);
        dispatch(setrewards([...getState().reward.rewards, response.data]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to update an existing reward
export const updateReward = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.patch(`/rewards/${data._id}`, data);
        const updatedrewards = getState().reward.rewards.map((reward) =>
            reward?._id === data?._id ? { ...reward, ...data } : reward
        );
        dispatch(setrewards(updatedrewards));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to delete an existing reward
export const deleteReward = (id) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.delete(`//rewards/${id}`);
        const filteredrewards = getState().reward.rewards.filter(
            (reward) => reward.id !== id
        );
        dispatch(setrewards(filteredrewards));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to get an reward by its ID
export const getRewardById = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get(`//rewards/${id}`);
        dispatch(setLoading(false));
        return response.data;
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
        return null;
    }
};
