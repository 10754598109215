import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    referralRewardHistorys: [],
};

const referralRewardHistory = createSlice({
    name: "referralRewardHistory",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setReferralRewardHistory(state, action) {
            state.referralRewardHistorys = action.payload;
        },
    },
});

export default referralRewardHistory.reducer;

export const { hasError, setLoading, setReferralRewardHistory } = referralRewardHistory.actions;

export const getAllReferralRewardHistory = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/referral-reward-history");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setReferralRewardHistory(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};


