import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    temblerHistorys: [],
};

const temblerHistory = createSlice({
    name: "temblerHistory",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setTemblerHistory(state, action) {
            state.temblerHistorys = action.payload;
        },
    },
});

export default temblerHistory.reducer;

export const { hasError, setLoading, setTemblerHistory } = temblerHistory.actions;

export const getTemblerHistory = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/tembler-history");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setTemblerHistory(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};


