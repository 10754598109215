// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    QuestionCircleOutlined,RollbackOutlined
} from '@ant-design/icons';

// icons
const icons = {
    QuestionCircleOutlined,RollbackOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
    id: 'group-reports',
    title: "QnA",
    type: 'group',
    children: [
        {
            id: 'question',
            title: 'Question',
            type: 'item',
            url: '/question',
            icon: icons.QuestionCircleOutlined,
        },
        {
            id: 'answer',
            title: 'Answer',
            type: 'item',
            url: '/answer',
            icon: icons.RollbackOutlined,
        }
    ]
};

export default reports;
