import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import {setrewards} from "./reward";

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    isLoading: false,
    categories: [],
    totalPages: 0,
};

const slice = createSlice({
    name: "category",
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        setCategories(state, action) {
            state.categories = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllCategory(perPage, page ,query) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const {title} = query;
            const response = await axios.get("/categories", {
                params: {
                    page,
                    perPage,
                    query:title
                },
            });
            await dispatch(slice.actions.setCategories(response.data.data));
            await dispatch(slice.actions.setTotalPages(response.data.totalPages));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}


export function addCategory(categoryData) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const response = await axios.post("/categories", categoryData);
            // Dispatch any additional actions  category add in state
            await dispatch(slice.actions.setCategories([response.data, ...getState().category.categories]));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}

export function updateCategory(data) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            const response = await axios.patch(`/categories/${data._id}`, data);
            const updatedCategories = getState().category.categories.map((category) =>
                category?._id === data?._id ? { ...category, ...data } : category
            );
            // Dispatch the action to update the categories in the state
            dispatch(slice.actions.setCategories(updatedCategories));

            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}

export function deleteCategory(categoryId) {
    return async (dispatch, getState) => {
        try {
            await dispatch(slice.actions.setLoading(true));
            await axios.delete(`/categories/${categoryId}`);
            // Dispatch any additional actions you may need, like removing the deleted category from state
            await dispatch(slice.actions.setCategories(getState().category.categories.filter((category) => category._id !== categoryId)));
            await dispatch(slice.actions.setLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            await dispatch(slice.actions.setLoading(false));
        }
    };
}
