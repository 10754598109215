import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    questions: [],
};

const question = createSlice({
    name: "question",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setquestions(state, action) {
            state.questions = action.payload;
        },
    },
});

export default question.reducer;

export const { hasError, setLoading, setquestions } = question.actions;

// API function to get all questions
export const getAllQuestions = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/questions");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setquestions(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new question
export const addQuestion = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/questions", data);
        dispatch(setquestions([ response.data, ...getState().question.questions]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to update an existing question
export const updateQuestion = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.patch(`/questions/${data._id}`, data);
        const updatedquestions = getState().question.questions.map((question) =>
            question?._id === data?._id ? { ...question, ...data } : question
        );
        dispatch(setquestions(updatedquestions));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to delete an existing question
export const deleteQuestion = (id) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        await axios.delete(`/questions/${id}`);
        const filteredquestions = getState().question.questions.filter(
            (question) => question._id !== id
        );
        dispatch(setquestions(filteredquestions));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

