// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {SettingOutlined} from '@ant-design/icons';

// icons
const icons = {
    SettingOutlined
};

// ==============================|| MENU ITEMS - General Settings ||============================== //

const dashboard = {
    id: 'group-dashboard',
    type: 'group',
    children: [
        {
            id: 'general-settings',
            title: 'Settings',
            type: 'item',
            url: '/general-settings',
            icon: icons.SettingOutlined,
        }
    ]
};

export default dashboard;
