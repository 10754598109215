// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {MessageOutlined} from '@ant-design/icons';

// icons
const icons = {
    MessageOutlined
};

// ==============================|| MENU ITEMS - Broadcasts ||============================== //

const dashboard = {
    id: 'group-dashboard',
    type: 'group',
    children: [
        {
            id: 'broadcasts',
            title: 'Broadcast Messages',
            type: 'item',
            url: '/broadcasts',
            icon: icons.MessageOutlined,
        }
    ]
};

export default dashboard;
