import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    coinTransfers: [],
};

const slice = createSlice({
    name: "account",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setCoinTransfers(state, action) {
            state.coinTransfers = action.payload;
        },
    },
});

export default slice.reducer;

export const { hasError, setLoading, setCoinTransfers } = slice.actions;

export function getAllCoinTransfers() {
    return async (dispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await axios.get("/coin-transfer-history");
            // Sort by createdAt in descending order
            const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            dispatch(setCoinTransfers(sorted)); // Assuming API response contains coinTransfers
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
        }
    };
}

