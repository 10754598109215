import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    broadcasts: [],
};

const broadcast = createSlice({
    name: "broadcast",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setbroadcasts(state, action) {
            state.broadcasts = action.payload;
        },
    },
});

export default broadcast.reducer;

export const { hasError, setLoading, setbroadcasts } = broadcast.actions;

// API function to get all broadcasts
export const getAllBroadcasts = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/notification-templates");
        // Sort by createdAt in descending order
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        dispatch(setbroadcasts(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new broadcast
export const addBroadcast = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/notification-templates", data);
        dispatch(setbroadcasts([response.data, ...getState().broadcast.broadcasts]));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};
