// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import category from './category';
import adAddon from './adAddon';
import pack from "./package";
import order from "./order";
import creditCard from "./credit-card";
import question from "./question";
import reward from "./reward";
import answer from "./answer";
import asset from "./asset";
import coinTransfer from "./coinTransferHistory";
import rewardHistory from "./rewardHistory";
import broadcast from "./broadcast";
import assetCategory from "./assetCategory";
import generalSetting from "./generalSetting";
import userModule from "./userModule"
import image from "./image"
import referralRewardHistory from "./referralRewardHistory"
import temblerHistory from "./temblerHistory"

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  category,
  adAddon,
  pack,
  order,
  creditCard,
  question,
  reward,
  answer,
  asset,
  coinTransfer,
  rewardHistory,
  broadcast,
  assetCategory,
  generalSetting,
  userModule,
  image,
  referralRewardHistory,
  temblerHistory
});

export default reducers;
