import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import useConfig from 'hooks/useConfig';
import Search from './Search';
import Message from './Message';
import Profile from './Profile';
import Localization from './Localization';
import Notification from './Notification';
import Customization from './Customization';
import MobileSection from './MobileSection';
import MegaMenuSection from './MegaMenuSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line
  const localization = useMemo(() => <Localization />, [i18n]);

  const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {/*{!matchesXs && <Search />}*/}
      {/*{!matchesXs && megaMenu}*/}
      {/*<Notification />*/}
      {/*<Message />*/}

      <Box sx={{ width: '100%', ml: 1 }} />
      {/*{localization}*/}
      {/*<Customization />*/}
      <Profile />

      {/*{matchesXs && <MobileSection />}*/}
    </>
  );
};

export default HeaderContent;
