import {lazy} from "react";
import {useRoutes} from "react-router-dom";

// project import
import MainLayout from 'layout/MainLayout';
// eslint-disable-next-line no-unused-vars
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// app import
import {authRoutes} from "./auth";

const Categories = Loadable(lazy(() => import('pages/categories')));
const AdAddons = Loadable(lazy(() => import('pages/ad-addon')));
const Package = Loadable(lazy(() => import('pages/package')));
const Order = Loadable(lazy(() => import('pages/order')));
const CreditCard = Loadable(lazy(() => import('pages/credit-card')));
const Question = Loadable(lazy(() => import('pages/question')));
const Reward = Loadable(lazy(() => import('pages/rewards')));
const Answer = Loadable(lazy(() => import('pages/answer')));
const Asset = Loadable(lazy(() => import('pages/assets')));
const CoinTransferHistory = Loadable(lazy(() => import('pages/coinTransferHistory')));
const UserModules = Loadable(lazy(() => import('pages/userModules')));
const RewardHistory = Loadable(lazy(() => import('pages/rewardHistory')));
const Broadcast = Loadable(lazy(() => import('pages/broadcast')));
const AssetCategory = Loadable(lazy(() => import('pages/assetCategory')));
const GeneralSetting = Loadable(lazy(() => import('pages/generalSetting')));
const ReferralRewardHistory = Loadable(lazy(() => import('pages/referralRewardHistory')));
const TemblerHistory = Loadable(lazy(() => import('pages/temblerHistory')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));

export default function ThemeRoutes() {
    return useRoutes([
        {
            element: (
                <AuthGuard>
                    <MainLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: "/",
                    element: <UserModules/>,
                },
                {
                    path: "/categories",
                    element: <Categories/>,
                },
                {
                    path: "/addons",
                    element: <AdAddons/>,
                },
                {
                    path: "/question",
                    element: <Question/>,
                },
                {
                    path: "/answer",
                    element: <Answer/>,
                },
                {
                    path: "/assetCategory",
                    element: <AssetCategory/>,
                },
                {
                    path: "/assets",
                    element: <Asset/>,
                },
                {
                    path: "/coin-transfer-history",
                    element: <CoinTransferHistory/>,
                },
                {
                    path: "/credit-card",
                    element: <CreditCard/>,
                },
                {
                    path: "/packages",
                    element: <Package/>,
                },
                {
                    path: "/rewards",
                    element: <Reward/>,
                },
                {
                    path: "/referral-reward-history",
                    element: <ReferralRewardHistory/>,
                },
                {
                    path: "/tembler-history",
                    element: <TemblerHistory/>,
                },
                {
                    path: "/reward-history",
                    element: <RewardHistory/>,
                },
                {
                    path: "/general-settings",
                    element: <GeneralSetting/>,
                },
                {
                    path: "/user-modules",
                    element: <UserModules/>,
                },
                {
                    path: "/order",
                    element: <Order/>,
                },
                {
                    path: "/broadcasts",
                    element: <Broadcast/>,
                },
            ]
        },
        ...authRoutes,
        {
            path: '*',
            element: <MaintenanceError/>
        }
    ]);
}