import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    orders: [],
    totalPages: 0,
};

const order = createSlice({
    name: "order",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setOrders(state, action) {
            state.orders = action.payload;
        },

        setTotalPages(state, action) {
            state.totalPages = action.payload;
        }
    },
});

export default order.reducer;

export const { hasError, setLoading, setOrders, setTotalPages } = order.actions;

export function getAllOrders(perPage, page) {
    return async (dispatch) => {
        try {
            await dispatch(order.actions.setLoading(true));
            const response = await axios.get("/orders", {
                params: {
                    page,
                    perPage,
                },
            });
            await dispatch(order.actions.setOrders(response.data.data));
            await dispatch(order.actions.setTotalPages(response.data.totalPages));
            await dispatch(order.actions.setLoading(false));
        } catch (error) {
            dispatch(order.actions.hasError(error));
            await dispatch(order.actions.setLoading(false));
        }
    };
    // return async (dispatch) => {
    //     try {
    //         dispatch(setLoading(true));
    //         const response = await axios.get("/orders");
    //         // Sort by createdAt in descending order
    //         const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    //         dispatch(setOrders(sorted));
    //         dispatch(setLoading(false));
    //     } catch (error) {
    //         dispatch(hasError(error));
    //         dispatch(setLoading(false));
    //     }
    // };
}


export function updateOrder(id, data) {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));
            await axios.patch(`/orders/${id}`, data);
            const updatedOrders = getState().order.orders.map((order) => {
                if (order.id === id) {
                    return { ...order, ...data };
                }
                return order;
            });
            dispatch(setOrders(updatedOrders));
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(hasError(error));
            dispatch(setLoading(false));
        }
    };
}

