// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    BorderOuterOutlined,BorderInnerOutlined
} from '@ant-design/icons';

// icons
const icons = {
    BorderOuterOutlined,BorderInnerOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
    id: 'group-reports',
    title: "Assets",
    type: 'group',
    children: [
        {
            id: 'assetCategory',
            title: 'Categories',
            type: 'item',
            url: '/assetCategory',
            icon: icons.BorderInnerOutlined,
        },
        {
            id: 'assets',
            title: 'Assets',
            type: 'item',
            url: '/assets',
            icon: icons.BorderOuterOutlined,
        },
    ]
};

export default reports;
