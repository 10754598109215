import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    answers: [],
};

const answerSlice = createSlice({
    name: "answer",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setAnswers(state, action) {
            state.answers = action.payload;
        },
    },
});

export default answerSlice.reducer;

export const { hasError, setLoading, setAnswers } = answerSlice.actions;

// Action to get all answers
export const getAllAnswers = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get('/answers');
        const sorted = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch(setAnswers(sorted));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error.message));
        dispatch(setLoading(false));
    }
};

