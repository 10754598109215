// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    InboxOutlined,CreditCardOutlined
} from '@ant-design/icons';

// icons
const icons = {
    InboxOutlined,CreditCardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const reports = {
    id: 'group-reports',
    title: "Packages",
    type: 'group',
    children: [
        {
            id: 'packages',
            title: 'Packages',
            type: 'item',
            url: '/packages',
            icon: icons.InboxOutlined,
        },
        {
            id: 'credit-card',
            title: 'Credit Card',
            type: 'item',
            url: '/credit-card',
            icon: icons.CreditCardOutlined,
        }
    ]
};

export default reports;
