import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    error: null,
    isLoading: false,
    generalSettings: [],
};

const generalSetting = createSlice({
    name: "generalSetting",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        setgeneralSettings(state, action) {
            state.generalSettings = action.payload;
        },
    },
});

export default generalSetting.reducer;

export const { hasError, setLoading, setgeneralSettings } = generalSetting.actions;

// API function to get all generalSettings
export const getAllGeneralSettings = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.get("/settings");
        dispatch(setgeneralSettings(response.data));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};

// API function to add a new generalSetting
export const addGeneralSetting = (data) => async (dispatch, getState) => {
    try {
        dispatch(setLoading(true));
        const response = await axios.post("/settings", data);
        // dispatch action to update the generalSettings state
        dispatch(setgeneralSettings(response.data));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(hasError(error));
        dispatch(setLoading(false));
    }
};



